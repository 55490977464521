import React from "react";

import moment from "moment";
import { Form, Button, Dropdown, Grid, Segment, Icon, Message, Header, Progress, Loader, Modal, Table, } from 'semantic-ui-react';
import { present, errorFor, formInput, adaptOnChangeToClassicFormOnChange, renderProspectCampaignAssocForTableView, } from "../shared/utils.js";
import CrmTaskListWithUnroutedModal from "./CrmTaskListWithUnroutedModal.js";
import axios from "axios";
import CrmTaskFormSimpleOpenClose from "./CrmTaskFormSimpleOpenClose.js";
import ProspectCrmStatusChange from  "./ProspectCrmStatusChange.js";
import ManuallyRequestedLinkedInOutreachFormSimpleOpenClose from "./ManuallyRequestedLinkedInOutreachFormSimpleOpenClose.js";
import ManuallyRequestedLinkedInOutreachList from "./ManuallyRequestedLinkedInOutreachList.js";
import Attachment from "../components/Chat/Conversation/Attachment.js";

export default class ProspectView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      crmTasks: [],
      loading: false,
      tasksDidLoadOnce: false,
      prospect: { prospectCampaignAssociations: [], manuallyRequestedLinkedInOutreaches: [], triggeredManuallyRequestedLinkedInOutreaches: [], tagIds: [] },
      tags: props.tags || [],
      addDataProviderInformation: {},
    }
    this.handleOpen = this.handleOpen.bind(this);
    this.loadTasks = this.loadTasks.bind(this);
    this.onNewTaskSaved = this.onNewTaskSaved.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onProspectChangeWithoutNotes = this.onProspectChangeWithoutNotes.bind(this);
    this.onCrmTaskRefreshed = this.onCrmTaskRefreshed.bind(this);
    this.loadProspect = this.loadProspect.bind(this);
    this.onManuallyRequestedLinkedInOutreachChange = this.onManuallyRequestedLinkedInOutreachChange.bind(this)
    this.onManuallyRequestedLinkedInOutreachAdded = this.onManuallyRequestedLinkedInOutreachAdded.bind(this)
    this.onManuallyRequestedLinkedInOutreachRemoved = this.onManuallyRequestedLinkedInOutreachRemoved.bind(this)
  }

  handleOpen() {
    this.loadTasks();
  }

  componentDidMount() {
    this.loadProspect();
    this.loadTasks();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.id !== this.props.id) {
      this.loadProspect()
      this.loadTasks()
    }

    if (prevState.prospect.id && Array.isArray(this.state.prospect.tagIds) && Array.isArray(prevState.prospect.tagIds) && prevState.prospect.tagIds.length != this.state.prospect.tagIds.length) {
      this.updateProspectTags(this.state.prospect.tagIds)
    }
  }

  frontendCompanyUrl(url) {
    return url.replace("/sales", "")
  }

  renderCompanyLink(prospect) {
    var name = present(prospect.primaryCompanyName) ? prospect.primaryCompanyName : ""
    if (present(prospect.primaryCompanyLinkedinUrl)) {
      return (
        <a href={this.frontendCompanyUrl(prospect.primaryCompanyLinkedinUrl)} target="_blank">{name}</a>
      )
    } else {
      return (
        <React.Fragment> {name} </React.Fragment>
      )
    }
  }

  handlePopupActivation = (popupId) => {
    this.setState({ activePopup: popupId });
  };

  onProspectFormChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const toChange = { ...this.state.prospect }
    toChange[name] = value
    this.setState({ prospect: toChange })
    this.props.onProspectChange(toChange)
  }

  onProspectChangeWithoutNotes(prospect) {
    let notesPreservedProspect = { ...prospect, notes: this.state.prospect.notes }
    this.setState({ prospect: notesPreservedProspect })
    this.props.onProspectChange(notesPreservedProspect)
  }

  renderManuallyCreatedAssoc(assoc) {
    let user = this.userWithId(assoc.linkedInOutreach.createdByUserId)
    return <React.Fragment>
      Manually created at: {this.l(assoc.manually_created_at)}<br />
      Manually created by: {user ? user.email : "unknown"}
    </React.Fragment>
  }

  renderNormallyCreatedAssoc(assoc) {
    let manuallyRequestedLinkedInOutreach = this.state.prospect.manuallyRequestedLinkedInOutreaches.find(o => o.linkedInOutreach && o.linkedInOutreach.id === assoc.linkedInOutreach.id);
    return (
      <React.Fragment>
        { manuallyRequestedLinkedInOutreach && <React.Fragment><span>This outreach was created by a <a href={"/manually_requested_linked_in_outreaches/"+manuallyRequestedLinkedInOutreach.id}>manual connection request</a>.</span><br /></React.Fragment> }
        Sent request at: { this.l(assoc.linkedInOutreach.sentConnectionRequestAt) }<br />
        Replied at: { present(assoc.linkedInOutreach.repliedAt) ? this.l(assoc.linkedInOutreach.repliedAt) : "-" }
      </React.Fragment>
    );
  }

  renderReply(assoc, prospect) {
    if (assoc.linkedInOutreach.repliedVia === "email") {
      return (
        <span style={{ display: "flex" }}>
          Email:&nbsp;
          {renderProspectCampaignAssocForTableView(assoc, prospect, 1, "right center", this.handlePopupActivation, this.state.activePopup) }
        </span>
      );
    }
    if (assoc.linkedInOutreach.threadUrl) {
      return (
        <span style={{ display: "flex" }}>
          Thread:&nbsp;
          {renderProspectCampaignAssocForTableView(assoc, prospect, 1, "right center", this.handlePopupActivation, this.state.activePopup)}
        </span>
      );
    }
    return null;
  }

  renderAssoc(assoc, prospect, i) {
    if (!present(assoc.linkedInOutreach)) return null;

    let anyAttachments = assoc.linkedInOutreach.linkedInOutreachThreads.reduce((acc, thread) => {
      return acc || thread.attachments.length > 0
    }, false)
    let noRenderedAttachments = 0;

    return (
      <div key={i}>
        { present(assoc.linkedInOutreach.manuallyCreatedAt) ? this.renderManuallyCreatedAssoc(assoc) : this.renderNormallyCreatedAssoc(assoc) }<br />
        { this.renderReply(assoc, prospect) }
        {anyAttachments > 0 && <>Attachments:&nbsp;</>}
        {assoc.linkedInOutreach.linkedInOutreachThreads.map((linkedInOutreachThread) => {
          return linkedInOutreachThread.attachments.map((a) => {
            noRenderedAttachments += 1
            return (
              <span style={{ display: 'inline-block'}}>
                {noRenderedAttachments > 1 && (<span>, &nbsp;</span>)}
                <Attachment
                  key={a.linkedInAttachmentId}
                  text={a.path}
                  downloadUrl={`/linked_in_threads/${linkedInOutreachThread.linkedInThread.id}/attachment/${a.path}`}
                  attachmentSize={a.size}
                  attachment={a.path}
                  showPreviewOnHover={true}
                  hideIcon={true}
                  hideSize={true}
                />
              </span>
            )
          })
        })}
      </div>
    )
  }

  onNewTaskSaved(crmTask) {
    this.setState({
      crmTasks: [crmTask, ...this.state.crmTasks, ],
    });
    if (present(crmTask.prospect)) {
      this.onProspectChangeWithoutNotes(crmTask.prospect)
    }
    this.props.onCrmTaskAdded && this.props.onCrmTaskAdded(crmTask)
  }

  loadProspect(callbackProspectData = false) {
    this.setState({ loading: true })
    axios.get(`/prospects/${this.props.id}.json`)
      .then(({ data }) => {
        this.setState({
          prospect: data,
          loading: false,
        })
        if (callbackProspectData) {
          this.props.onProspectChange(data)
        }
      })
      .catch(error => {
        this.setState({ loading: false, errorMessage: "Prospect not found", })
        console.error("an error occured loading prospect", error);
      })
  }

  updateProspect() {
    let { prospect } = this.state
    if (!present(prospect.id)) { return }
    axios.post(`/prospects/${prospect.id}`, { id: prospect.id, notes: prospect.notes })
      .then(({data}) => {
        console.log("update success");
        console.log(data);
      })
  }

  loadTasks() {
    this.setState({ loading: true })
    axios.get(`/crm_tasks.json?associated_with[]=${this.props.id}`)
      .then(({ data }) => {
        this.setState({
          loading: false,
          tasksDidLoadOnce: true,
          crmTasks: data.crmTasks,
        })
      })
      .catch(error => {
        this.setState({ loading: false, })
        console.error("an error occured loading crmTasks", error);
      })
  }

  onClose() {
    this.props.onClose && this.props.onClose();
    this.updateProspect();
  }

  onCrmTaskRefreshed(task) {
    this.loadTasks()
    if (present(task.prospect)) {
      this.onProspectChangeWithoutNotes(task.prospect)
    }
    this.props.onCrmTaskChange && this.props.onCrmTaskChange(task)
  }

  userWithId(id) {
    return this.props.users.find(u => u.id == id)
  }

  l(date) {
    if (!present(date)) return "-"
    return moment(date).format("DD.MM.YYYY")
  }

  lHour(date) {
    if (!present(date)) return "-"
    return moment(date).format("DD.MM.YYYY HH:mm")
  }

  onManuallyRequestedLinkedInOutreachChange(o) {
    this.loadProspect()
    this.props.onManuallyRequestedLinkedInOutreachChange && this.props.onManuallyRequestedLinkedInOutreachChange(o)

  }

  onManuallyRequestedLinkedInOutreachAdded(o) {
    this.loadProspect()
    this.props.onManuallyRequestedLinkedInOutreachAdded && this.props.onManuallyRequestedLinkedInOutreachAdded(o)
  }

  onManuallyRequestedLinkedInOutreachRemoved(o) {
    this.loadProspect()
    this.props.onManuallyRequestedLinkedInOutreachRemoved && this.props.onManuallyRequestedLinkedInOutreachRemoved(o)
  }

  handleTagCreation(tag) {
    axios.post('/prospects/tags', { name: tag })
      .then(({ data }) => {
        if (this.props.setTags) { this.props.setTags(data) }
        if (this.state.prospect.tagIds.find(t => t == data.find(t => t.name == tag).id)) {
          this.setState({
            tags: data
          })
        } else {
          this.setState({
            prospect: { ...this.state.prospect, tagIds: this.state.prospect.tagIds.concat(data.find(t => t.name == tag).id) },
            tags: data
          })
        }
      })
      .catch(error => {
        console.log("error when creating tag ", error);
      });
  }

  updateProspectTags(tagIds) {
    axios.post(`/prospects/tags/${this.state.prospect.id}`, { tagIds }).then(({ data }) => {
      if (this.props.setTags) {
        this.props.setTags(data)
      }
      this.setState({ tags: data })
    }).catch(error => {
      console.log(error)
    })
  }

  handleDataProviderInformationCreation(dataProviderInformation) {
    axios.post('/prospects/data_provider_information', { ...dataProviderInformation, prospectId: this.state.prospect.id })
      .then((response) => {
        let prospect = response.data
        this.setState({ addDataProviderInformation: {} })
        this.onProspectChangeWithoutNotes(prospect)
      })
      .catch(error => {
        console.error("error when creating data provider information ", error);
      });
  }

  handleDataProviderInformationDeletion(dataProviderInformation) {
    axios.delete(`/prospects/data_provider_information/${dataProviderInformation.id}?prospectId=${this.state.prospect.id}`)
      .then((response) => {
        let prospect = response.data
        this.setState({ addDataProviderInformation: {} })
        this.onProspectChangeWithoutNotes(prospect)
      })
      .catch(error => {
        console.error("error when deleting data provider information ", error);
      });
  }

  noErrorModalContent() {
    let { users, crmStatuses, tags, currentUserCanSeeProspectDetails } = this.props
    let { prospect } = this.state;

    tags ||= [];

    let dataProviderInformation = prospect.dataProviderInformation || []
    dataProviderInformation = dataProviderInformation.filter((dpi, i) =>
      dataProviderInformation.findIndex(dpi2 => (dpi.value === dpi2.value)) === i
    )

    return (
      <React.Fragment>
        <h3 style={{lineHeight: "42.2px"}}> { this.renderCompanyLink(prospect) } - { prospect.title } </h3>

        {dataProviderInformation.map((dpi, i) => {
          if (this.state.addDataProviderInformation.id == dpi.id) return null;

          return (
            <p key={i}>
              {dpi.type == "phone" && (
                <>
                  <i className="fa-solid fa-phone" style={{ marginRight: 5 }}></i>
                  <a href={`tel:${dpi.value}`}>{dpi.value}</a>
                </>
              )}
              {dpi.type == "email" && (
                <>
                  <i className="fa-solid fa-envelope" style={{ marginRight: 5 }}></i>
                  <a href={`mailto:${dpi.value}`}>{dpi.value}</a>
                </>
              )}
              <span style={{ marginLeft: 5, color: 'gray' }}>({dpi.source})</span>
              {dpi.dataProvider === 'User' && (
                <i
                  className="fa-solid fa-pen"
                  style={{ marginLeft: 5, cursor: 'pointer' }}
                  onClick={() => this.setState({ addDataProviderInformation: { id: dpi.id, type: dpi.type, value: dpi.value } })}
                ></i>
              )}
            </p>
          )
        })}

        {currentUserCanSeeProspectDetails && !present(this.state.addDataProviderInformation) && (
          <div style={{ marginBottom: 10, marginTop: 10 }}>
            <Button
              content="Add Phone Number"
              size="mini"
              onClick={() => this.setState({ addDataProviderInformation: { type: 'phone' } })}
            />
            <Button
              content="Add Email Address"
              size="mini"
              onClick={() => this.setState({ addDataProviderInformation: { type: 'email' } })}
            />
          </div>
        )}

        {present(this.state.addDataProviderInformation) && (
          <Form.Field style={{ marginBottom: 10 }}>
            <Form.Input type='text' action>
              <i
                className={`fa-solid fa-${this.state.addDataProviderInformation.type == 'email' ? 'envelope' : 'phone'}`}
                style={{ marginRight: 5, marginTop: 12 }}
              ></i>
              <input
                type={this.state.addDataProviderInformation.type == 'email' ? 'email' : 'tel'  }
                value={this.state.addDataProviderInformation?.value || ''}
                onChange={(e) => this.setState({ addDataProviderInformation: { ...this.state.addDataProviderInformation, value: e.target.value }})}
              />
              <Button onClick={() => this.handleDataProviderInformationCreation(this.state.addDataProviderInformation)}>Save</Button>
              {this.state.addDataProviderInformation.id && (
                <Button
                  color="red"
                  onClick={() => this.handleDataProviderInformationDeletion(this.state.addDataProviderInformation)}
                >
                  Delete
                </Button>
              )}
              <Button basic onClick={() => this.setState({ addDataProviderInformation: {} })}>Cancel</Button>
            </Form.Input>
          </Form.Field>
        )}

        <Form>
          <ProspectCrmStatusChange
            prospect={prospect}
            crmStatuses={crmStatuses}
            users={users}
            onProspectChange={this.onProspectChangeWithoutNotes}
            label={"Crm Status"}
          />
          <Form.Field>
            <label>Tags</label>
            <Dropdown
              multiple
              search
              options={
                this.state.tags.map(tag => { return { key: tag.id, text: tag.name, value: tag.id } }).sort(
                  (a, b) => a.text.localeCompare(b.text)
                )
              }
              value={prospect.tagIds}
              allowAdditions
              onAddItem={(_, { value }) => this.handleTagCreation(value)}
              selection
              onChange={(_, { value }) => this.setState({ prospect: { ...prospect, tagIds: value.filter(v => !isNaN(v)) } })}
              selectOnBlur={false}
              clearSearchQueryAfterItemAdd={true}
            />
          </Form.Field>
          {
            formInput(
              prospect,
              "notes",
              "Notes",
              this.onProspectFormChange.bind(this),
              Form.TextArea,
            )
          }
        </Form>

        { present(prospect.prospectCampaignAssociations) && <h2>Campaigns</h2> }
        { prospect.prospectCampaignAssociations.map((assoc, i) => {
          return <Segment key={i}>
            <h3>{ assoc.campaign.name }</h3>
            { this.renderAssoc(assoc, prospect, i) }
          </Segment>
        }) }

          { present(prospect.crmStatusChanges) &&
              <React.Fragment>
                <h2>Status History</h2>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                      <Table.HeaderCell>Received at</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    { prospect.crmStatusChanges.map((change, i) => {
                      return <Table.Row key={i}>
                        <Table.Cell style={{width: "50%"}}>{ change.crmStatusAfter ? change.crmStatusAfter.name : <i>no status</i> }</Table.Cell>
                        <Table.Cell style={{width: "50%"}}>{ this.lHour(change.changedAt) }</Table.Cell>
                      </Table.Row>
                    }) }
                  </Table.Body>
                </Table>
              </React.Fragment>
          }


        <Grid style={{marginTop: 30}}>

          <Grid.Row>
            <Grid.Column width={13}>
              <Header as='h2' floated='left'>
                Tasks
              </Header>
            </Grid.Column>
            <Grid.Column width={3} float="right" style={{textAlign: "right"}}>
              <CrmTaskFormSimpleOpenClose
                trigger={<Button size="mini" labelPosition='left' icon><Icon name="add"></Icon>Create Task</Button>}
                onSave={this.onNewTaskSaved}
                defaultCrmTask={{ prospectId: prospect.id, userId: this.props.currentUserId, }}
                defaultProspects={[prospect]}
                users={users}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>


        { this.state.crmTasks.length > 0 && <CrmTaskListWithUnroutedModal
          crmTasks={this.state.crmTasks}
          users={users}
          onCrmTaskRefreshed={this.onCrmTaskRefreshed}
        />
        }
        { this.state.crmTasks.length === 0 && this.state.tasksDidLoadOnce && <Message icon>
          <Icon name='filter' />
          <Message.Content>
            <Message.Header>No tasks for this prospect.</Message.Header>
          </Message.Content>
        </Message>
        }
        { (prospect.manuallyRequestedLinkedInOutreaches.concat(prospect.triggeredManuallyRequestedLinkedInOutreaches)).length > 0 &&
          <Grid style={{marginTop: 30}}>

            <Grid.Row>
              <Grid.Column width={13}>
                <Header as='h2' floated='left'>
                  Manual LinkedIn connection requests
                </Header>
              </Grid.Column>
              <Grid.Column width={3} float="right" style={{textAlign: "right"}}>
                <ManuallyRequestedLinkedInOutreachFormSimpleOpenClose
                  trigger={<Button size="mini" labelPosition='left' icon><Icon name="add"></Icon>Create request</Button>}
                  campaigns={this.props.campaigns}
                  onSave={this.onManuallyRequestedLinkedInOutreachAdded}
                  defaultRequestedOutreach={{
                    triggeringProspectId: prospect.id,
                    triggeringProspect: {
                      id: prospect.id,
                      name: prospect.name,
                    },
                    message: "",
                    followUps: [],
                  }}
                  users={this.props.users}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>

                { prospect.manuallyRequestedLinkedInOutreaches.length > 0 &&
                  <React.Fragment>
                    <Header as='h3' floated='left'>
                      ..to this prospect
                    </Header>
                    <ManuallyRequestedLinkedInOutreachList
                      manuallyRequestedLinkedInOutreaches={prospect.manuallyRequestedLinkedInOutreaches}
                      campaigns={this.props.campaigns}
                      onManuallyRequestedLinkedInOutreachRefreshed={this.onManuallyRequestedLinkedInOutreachChange}
                      onManuallyRequestedLinkedInOutreachRemoved={this.onManuallyRequestedLinkedInOutreachRemoved}
                      routed={false}
                      crmStatuses={crmStatuses}
                    />
                  </React.Fragment>
                }
                { prospect.triggeredManuallyRequestedLinkedInOutreaches.length > 0 &&
                  <React.Fragment>
                    <Header as='h3' floated='left'>
                      ..from this prospect
                    </Header>
                    <ManuallyRequestedLinkedInOutreachList
                      manuallyRequestedLinkedInOutreaches={prospect.triggeredManuallyRequestedLinkedInOutreaches}
                      campaigns={this.props.campaigns}
                      onManuallyRequestedLinkedInOutreachRefreshed={this.onManuallyRequestedLinkedInOutreachChange}
                      onManuallyRequestedLinkedInOutreachRemoved={this.onManuallyRequestedLinkedInOutreachRemoved}
                      routed={false}
                      crmStatuses={crmStatuses}
                    />
                  </React.Fragment>
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>

        }

        { this.state.loading && <Loader active inline='centered' /> }


      </React.Fragment>
    );
  }

  errorModalContent() {
    return <Message
      header={this.state.errorMessage}
      content={"This prospect may have been deleted, or you don't have permission to view it. If you feel this prospect should be visible to you please contact us."}
    />
  }

  render () {
    let { prospect } = this.state;

    return (

      <React.Fragment>

        <Modal open={true} onClose={this.onClose}>
          <Modal.Header>
            <Grid>
              <Grid.Column width={15}>
                { present(prospect.linkedInProfileUrl) && <a href={prospect.linkedInProfileUrl} target="_blank" className="ui" aria-label="Profile"> <i className="fab fa-linkedin icon" style={{fontFamily: "'Font Awesome 6 Brands'", color: "#2E384D"}}></i> </a> }
                { prospect.name }
              </Grid.Column>
              <Grid.Column width={1}>
                <Icon name="close" onClick={this.onClose} style={{cursor: "pointer"}}/>
              </Grid.Column>
            </Grid>
          </Modal.Header>
          <Modal.Content>
             { present(this.state.errorMessage) ? this.errorModalContent() : this.noErrorModalContent() }
          </Modal.Content>

        </Modal>
      </React.Fragment>

    )
  }
}
